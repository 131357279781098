import { Menu } from "antd";
import React, { ReactNode, useContext } from "react";
import {
  DashboardOutlined,
  UserOutlined,
  MessageOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import CommonStore from "../../store/CommonStore";
import { Link, useLocation } from "react-router-dom";
import { AdminRoles } from "../../types/enums";

type MenuType = {
  key: string;
  label: string | ReactNode;
  icon: ReactNode;
  items?: { key: string; label: string | ReactNode; icon: ReactNode }[];
};

const { SubMenu, Item } = Menu;

const NavigationBar: React.FC<{ theme: "dark" | "light" }> = ({ theme }) => {
  //
  const { t } = useTranslation();

  //
  const storeCommon = useContext(CommonStore);

  //
  const location = useLocation();

  //
  const menu: { [key: number]: MenuType[] } = {
    [AdminRoles.SUPER_ADMIN]: [
      {
        key: "",
        label: t("Dasboard"),
        icon: <DashboardOutlined />,
      },
      {
        key: "users",
        label: t("Users"),
        icon: <UserOutlined />,
      },
      {
        key: "messages",
        label: t("Messages"),
        icon: <MessageOutlined />,
      },
      {
        key: "terms",
        label: t("Terms and Privacy"),
        icon: <QuestionCircleOutlined />,
      },
    ],

    // [AdminRoles.ADMIN]: [
    //   {
    //     key: '',
    //     label: t('Dasboard'),
    //     icon: <DashboardOutlined />,
    //   },
    // ],

    // [AdminRoles.MANAGER]: [
    //   {
    //     key: '',
    //     label: t('Dasboard'),
    //     icon: <DashboardOutlined />,
    //   },
    // ],

    // [AdminRoles.USER]: [
    //   {
    //     key: '',
    //     label: t('Dasboard'),
    //     icon: <DashboardOutlined />,
    //   },
    // ],
  };

  const activeRoute = location.pathname.split("/")[1] || "";

  //
  const openedKey = () => {
    let ret = "";
    if (storeCommon.user) {
      menu[storeCommon.user.role].forEach((item) => {
        const search = item.items?.find((sub) => {
          return sub.key.split("/")[0] === activeRoute;
        });
        if (search) {
          ret = item.key;
          return;
        }
      });
    }
    return ret;
  };

  return (
    <Menu
      // onClick={this.handleClick}
      id={`menu-${storeCommon.user?._id}`}
      style={{ width: 256 }}
      mode="inline"
      theme={theme}
      defaultOpenKeys={[openedKey()]}
      defaultSelectedKeys={[activeRoute]}
      key={`menu-${activeRoute}`}
    >
      {storeCommon.user &&
        menu[storeCommon.user.role].map((item, index) => {
          return (
            <React.Fragment key={index}>
              {!item.items && (
                <Item icon={item.icon} key={item.key}>
                  <Link to={`/${item.key}`}>{item.label}</Link>
                </Item>
              )}
              {item.items && (
                <SubMenu key={item.key} icon={item.icon} title={item.label}>
                  {item.items.map((sub) => {
                    return (
                      <Item icon={sub.icon} key={sub.key}>
                        <Link to={`/${sub.key}`}>{sub.label}</Link>
                      </Item>
                    );
                  })}
                </SubMenu>
              )}
            </React.Fragment>
          );
        })}
    </Menu>
  );
};

export default NavigationBar;
