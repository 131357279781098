import { action, observable, makeObservable } from 'mobx';
import { createContext } from 'react';
// import api from "../api/api";
import { Admin } from '../types';

class CommonStore {
  user: Admin | null = null;

  constructor(user: Admin | null) {
    makeObservable(this, {
      user: observable,

      setUser: action,
    });

    this.user = user;

    // this.setInitValues();
  }

  // setInitValues = async () => {
  //   const token = localStorage.getItem("token");
  //   if (!token) return;

  //   //
  //   const user = await api("admins/me");
  //   if (user?.id) {
  //     this.user = user;
  //   }
  // };

  setUser = (user: Admin | null) => {
    this.user = user;
  };
}

export default createContext(new CommonStore(null));
