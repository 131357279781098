import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";

const Dashboard = lazy(() => import("../pages/Dashboard"));
const Users = lazy(() => import("../pages/Users"));

const Messages = lazy(() => import("../pages/Messages"));
const EditMessage = lazy(() => import("../pages/Messages/EditMessage"));

const Terms = lazy(() => import("../pages/Terms"));
const EditTerm = lazy(() => import("../pages/Terms/EditTerm"));

const AppRouter = () => {
  //
  // const storeCommon = useContext(CommonStore);

  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/users" element={<Users />} />

        <Route path="/messages" element={<Messages />} />
        <Route path="/messages/create" element={<EditMessage />} />
        <Route path="/messages/:messageId" element={<EditMessage />} />

        <Route path="/terms" element={<Terms />} />
        <Route path="/terms/create" element={<EditTerm />} />
        <Route path="/terms/:termId" element={<EditTerm />} />
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
