export enum AdminRoles {
  SUPER_ADMIN = 0,
  ADMIN = 100,
  MANAGER = 200,
  USER = 300,
}

export enum MessageStatus {
  NOT_ACTIVE = 0,
  ACTIVE = 10,
}

export enum TermsType {
  PRIVACY = 10,
  TERMS = 100,
}
